import React from "react";
// MUI https://v4.mui.com/
// import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import _ from "lodash";
import SimpleModal from "./SimpleModal";
// import { makeStyles } from "@material-ui/core/styles";

import { getSiteParams } from "../../configuration/wizardConfiguration";

// import FormLabel from "@material-ui/core/FormLabel";

// const useStyles = makeStyles({
//   imgDescription: {
//     marginBottom: "40px",
//     marginTop: "10px",
//   },
// });

const Quantity = ({ formData, setFormValues, option, card }) => {
  // const { cards } = radioGroup;
  const {description} = card;
  const updateQuantity = (updatedQ) => {
    let updatedFormData = { ...formData };

    if (isNaN(updatedQ) || updatedQ <= 0) {
      updatedFormData[option.value] = 0;
      // updatedFormData[`${option.value}__code`] = "";
      // updatedFormData[`${option.value}__description`] = "";
      updatedFormData[`${option.value}__NOPOST_smry`] = "";
      // updatedFormData[`${option.value}__summary_card`] = "";
    } else {
      updatedFormData[option.value] = updatedQ;
      // updatedFormData[`${option.value}__code`] = option.name;
      // updatedFormData[`${option.value}__description`] = option.label;
      // updatedFormData[`${option.value}__summary_card`] = `${description}:`;
      updatedFormData[`${option.value}__NOPOST_smry`] = `${description}|||Nr. ${updatedQ} ${option.label}`;
    }
    setFormValues({ ...updatedFormData });
  };

  const deltaQuantity = (delta) => {
    let updatedQ = formData[option.value] + delta;
    updateQuantity(updatedQ);
  };

  const setQuantity = (e) => {
    updateQuantity(parseInt(e.target.value));
  };

  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      <IconButton
        color="primary"
        component="span"
        onClick={() => {
          deltaQuantity(-1);
        }}
      >
        <RemoveIcon />
      </IconButton>
      <TextField
        // label={option.label}
        // label={"Q.tà"}
        name={option.name}
        value={formData[option.value]}
        onChange={setQuantity}
        margin="normal"
        variant="outlined"
        autoComplete="off"
        type="text"
        style={{ width: 70 }}
        inputProps={{
          min: 0,
          max: 100,
          style: {
            textAlign: "center",
            fontSize: "20px",
          },
        }}

        // inputProps={{ size: "3" }}
      />
      <IconButton
        color="primary"
        component="span"
        onClick={() => {
          deltaQuantity(+1);
        }}
      >
        <AddIcon />
      </IconButton>
    </Box>
  );
};
const PairedOptions = ({ formData, setFormValues, card }) => {
  // const rednderPairedOptions = (options) => {
    // const items = ['Apple', 'Banana', 'ddddOrange'];


  let pairedOpts = card.options.reduce((result, currentElement, index) => {
      index % 2 === 0
          ? result.push([currentElement])
          : result[result.length - 1].push(currentElement);
      return result;
  }, []);      

  return(
    pairedOpts.map((pair, pair_index) => {
      const optLeft = { ...pair[0] };      
      const optRight = { ...pair[1] };
      return (
        <Grid
          container
          // spacing={1}
          direction="row"
          // justify="flex-start"
          // alignItems="stretch"
        >
       <Grid container xs={6} direction="column" alignItems="center" id="left" >
          <Grid>
            <Box mt={3} mb={-2}>
              <Typography variant="body1" align="center">
                {optLeft.label}
              </Typography>
            </Box>
          </Grid>
          <Grid>            
            <Quantity          
              {...{ formData, setFormValues, option:optLeft, card }}
            />
          </Grid>
        </Grid>
       <Grid container xs={6} direction="column" alignItems="center" id="right" >
          <Grid>
            <Box mt={3} mb={-2}>
              <Typography variant="body1" align="center" >
                {optRight.label}
              </Typography>
            </Box>
          </Grid>
          <Grid>
            {(_.isEmpty(optRight)) ? 
              <></>
              :
              <Quantity
                {...{ formData, setFormValues, option:optRight, card }}
              />
            }
          </Grid>


        </Grid>
      </Grid>
      );
    })
  );

  };                


const SingleOption = ({ formData, setFormValues, card }) => {
  // const rednderPairedOptions = (options) => {
    // const items = ['Apple', 'Banana', 'ddddOrange'];

  return (
    <Grid
      container
      // spacing={1}
      direction="row"
      // justify="flex-start"
      // alignItems="stretch"
      alignItems="center" 
    >
    <Grid container xs={12} direction="column" alignItems="center" id="center" >
      <Grid>        
        <Box mt={3} mb={-2}>
          <Typography variant="body1" align="center">
            {card.options[0].label}  
          </Typography>
        </Box>
      </Grid>
      <Grid>            
        <Quantity
      
          {...{ formData, setFormValues, option:card.options[0], card }}
        />
      </Grid>
    </Grid>
  </Grid>
  );
};

export const CardsQuantitiesGroupStep = (props) => {
  // const classes = useStyles();

  const { formData, setForm, stepData, setFormValues } = props;
  const { radioGroup } = stepData.params;
  const { nbText } = stepData.params;
  const { cards } = radioGroup;
  const { img_base_url } = getSiteParams();

  
  // undefied quantities to 0  
  React.useEffect(() => {
    let updatedFormData = { ...formData };
    cards.forEach((card, card_index) => {
      const options  = card.options;
      options.forEach((option, opt_index) => {
        updatedFormData[option.value] = (updatedFormData[option.value] === undefined) ? 0 : updatedFormData[option.value];
      }); 
    });
    setFormValues({ ...updatedFormData });        
  // }); // run every render.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepData.id]); // run only once - each time steo id changes.



  const nbGlobal =
    nbText && nbText !== "" ? (
      <Box
        display="flex"
        color="red"
        width="100%"
        justifyContent="center"
        pt={3}
      >
        <Typography
          variant="body1"
          style={{ fontWeight: 600 }}
          align="center"
        >
          {nbText}
        </Typography>
      </Box>
    ) : (
      ""
    );


  return (
    <FormControl component="fieldset" fullWidth={true}>
      {/* <FormLabel component="legend">Brand</FormLabel> */}
      <RadioGroup
        aria-label={radioGroup.label}
        name={radioGroup.name}
        value={formData[radioGroup.value]}
        onChange={setForm}
      >
        <Grid
          item
          container
          direction="column"
          justify="space-around"
          alignItems="stretch"
        >
          <Grid
            item
            container
            spacing={1}
            alignContent="center"
            alignItems="flex-start"
            justify="space-around"
          >
            {cards.map((card, card_index) => {
              const modal =
                card.infoText && card.infoText !== "" ? (
                  <SimpleModal
                    {...{ title: card.description, content: card.infoText }}
                  />
                ) : (
                  ""
                );

              const nb =
                card.nbText && card.nbText !== "" ? (
                  <Box
                    display="flex"
                    color="red"
                    width="100%"
                    justifyContent="center"
                    pt={3}
                  >
                    <Typography
                      variant="body1"
                      style={{ fontWeight: 600 }}
                      align="center"
                    >
                      {card.nbText}
                    </Typography>
                  </Box>
                ) : (
                  ""
                );
              const imgDisclaimer =
                card.imgDisclaimer && card.imgDisclaimer !== "" ? (
                  <Typography variant="caption" align="center">
                    {card.imgDisclaimer}
                  </Typography>
                ) : (
                  ""
                );

            
              return (
                <Grid
                  item
                  container
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  direction="column"
                  spacing={1}
                  key={card_index}
                >
                  <Grid
                    item
                    container
                    direction="column"
                    justify="flex-end"
                    alignItems="center"
                    className="mediaContainer"
                  >
                    <img
                      src={`${img_base_url}${card.img_src}`}
                      alt={card.img_alt}
                      className="imgTile"
                    />
                  </Grid>


                  <Grid item container align="center" direction="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    {/* <Grid container
                    justifyContent="center"
                    alignItems="center"
                    > */}


                    {/* <Grid>        
                      <Box mt={3} mb={-2}>
                        <Typography variant="body1" align="center">
                          ciccio
                        </Typography>
                      </Box>
                    </Grid> */}

                      {imgDisclaimer}
                      <Typography variant="h6" align="center">
                        {card.description}
                      </Typography>

                      <div>{modal}</div>
                    {/* </Grid> */}

                    {(card.options.length>1) ?
                    <PairedOptions
                      {...{ formData, setFormValues, card }}
                    />                    
                    :
                    <SingleOption
                      {...{ formData, setFormValues, card }}
                    />
                    }
                    {/* <div>{modal}</div> */}
                    <div>{nb}</div>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
           <div>{nbGlobal}</div>
        </Grid>
      </RadioGroup>
    </FormControl>
  );
};
